import { render, staticRenderFns } from "./AvatarModal.vue?vue&type=template&id=d5d9e018&scoped=true&"
import script from "./AvatarModal.vue?vue&type=script&lang=js&"
export * from "./AvatarModal.vue?vue&type=script&lang=js&"
import style0 from "./AvatarModal.vue?vue&type=style&index=0&id=d5d9e018&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5d9e018",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/YGPay-Web-Merchant/jeepay-ui-merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d5d9e018')) {
      api.createRecord('d5d9e018', component.options)
    } else {
      api.reload('d5d9e018', component.options)
    }
    module.hot.accept("./AvatarModal.vue?vue&type=template&id=d5d9e018&scoped=true&", function () {
      api.rerender('d5d9e018', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/current/AvatarModal.vue"
export default component.exports